






































import { Component, Vue, Watch } from "vue-property-decorator";
import PhotoPreview from "@/components/PhotoPreview.vue";
import StreamHeader from "@/components/StreamHeader.vue";

import justifiedLayout from "justified-layout";
import ResizeObserver from 'resize-observer-polyfill';

@Component({
  components: {
    PhotoPreview,
    StreamHeader,
  },
  props: {
    sectionName: String,
    numPhotos: Number,
    shouldLoad: Boolean, // When this is false, detach the DOM elements and insert a placeholder spacer instead
  },
  data: () => ({
    scrollWidth: 0,
    resizeObserver: null,
    actualHeight: undefined
  }),
})
export default class PhotoSection extends Vue {
  resizeObserver!: ResizeObserver
  sectionName!: string
  actualHeight!: number

  created() {
    if (this.$props.shouldLoad) {
      this.$store.dispatch("loadSectionDetails", this.$props.sectionName);
    }
    this.resizeObserver = new ResizeObserver(entries => {
      this.$emit('height', {section: this.sectionName, height: entries[0].contentRect.height})
      this.$data.scrollWidth = entries[0].contentRect.width
    })
  }

  mounted() {
    //this.$data.scrollWidth = this.$el.scrollWidth;
    //window.addEventListener("resize", this.updateWidth.bind(this));
    this.resizeObserver.observe(this.$el)
  }

  unmounted() {
    //window.removeEventListener("resize", this.updateWidth.bind(this));
    this.resizeObserver.unobserve(this.$el)
  }

  updateWidth() {
    //this.$data.scrollWidth = this.$el.scrollWidth;
  }

  get spacerHeight() {
    const picsPerRow = Math.floor(this.$data.scrollWidth / 200)
    const rows = Math.ceil(this.$props.numPhotos / picsPerRow)

    return rows * 200
  }

  get photos() {
    return this.$store.state.sectionPhotos[this.$props.sectionName];
  }

  get justifiedLayout() {
    if (this.$props.shouldLoad && this.photos) {
      const layout = justifiedLayout(this.photos, {
        targetRowHeight: 200,
        containerWidth: this.$data.scrollWidth,
        boxSpacing: 5,
      })

      this.actualHeight = layout.containerHeight

      return layout
    } else {
      return justifiedLayout([]);
    }
  }

  get photoStyle() {
    return this.justifiedLayout.boxes.map(({ width, height, top, left }) => ({
      width: width + "px",
      height: height + "px",
      top: top + "px",
      left: left + "px",
      position: "absolute",
    }));
  }

  @Watch("shouldLoad")
  shouldLoadChanged(newValue: boolean) {
    if (newValue) {
      this.$store.dispatch("loadSectionDetails", this.$props.sectionName)
    } else {
      // TODO Purge section data
    }
  }

  showPhoto(photoId: string) {
    console.log(photoId)
  }
}
