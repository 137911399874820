import Vue from 'vue'

import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'

import { clientId, domain } from "../auth.config.json"

import { Auth0Plugin } from './auth'

import './index.css'


Vue.config.productionTip = false

Vue.use(Auth0Plugin, {
  domain,
  clientId,
  onRedirectCallback: (appState: any) => {
    router.push(
      appState && appState.targetUrl
        ? appState.targetUrl
        : window.location.pathname
    );
  }
})

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
