






import { Component, Vue } from 'vue-property-decorator';

@Component({
  props: {
    id: String
  }
})
export default class PhotoPreview extends Vue {
  id!: string

  get thumbnailData() {
    return this.$store.state.thumbnails[this.id]
  }
}
