import Vue from 'vue'
import Vuex from 'vuex'

import { audience } from '@/../auth.config.json'
import { base } from '@/../client.config.json'
import axios from 'axios'

Vue.use(Vuex)

axios.interceptors.request.use(async function (config) {
  const v = new Vue()
  const token = await v.$auth.getTokenSilently({audience})
  config.headers.Authorization =  'Bearer ' + token;

  return config;
});

axios.defaults.baseURL = base

enum PhotoStatus {
  UNLOADED = 'unloaded',
  LOADING = 'loading',
  LOADED = 'loaded',
  ERROR = 'error'
}

const store = new Vuex.Store({
  state: {
    sections: [],
    sectionPhotos: {},
    thumbnails: {},
    photos: {},
    status: {
      lightTable: {
        id: '',
        isOpen: false
      }
    },
    buckets: [],
    loading: {}
  },
  mutations: {
    setLoadingState(storeState, { key, loadingState}) {
      storeState.loading = {
        ...storeState.loading,
        [key]: loadingState
      }
    },
    setSections(state, sections) {
      state.sections = sections
    },
    mergeThumbnails(state, thumbnails) {
      state.thumbnails = {
        ...state.thumbnails,
        ...thumbnails
      }
    },
    mergeSectionDetails(state, sectionDetails) {
      state.sectionPhotos = {
        ...state.sectionPhotos,
        [sectionDetails.name]: sectionDetails.content.photos
      }
    },
    setPhoto(state, {id, data}) {
      state.photos = {
        ...state.photos,
        [id]: data
      }
    },
    mergeStatus(state, status) {
      state.status = {
        ...state.status,
        ...status
      }
    },
    setBuckets(state, buckets) {
      state.buckets = buckets
    }
  },
  actions: {
    async saveBucket(context, bucketData) {
      try {
        context.commit('setLoadingState', {
          key: 'bucketSave',
          loadingState: {
            status: 'loading'
          }
        })

        if(bucketData.id) {
          const result = (await axios.put('/buckets/' + bucketData.id, bucketData))
        } else {
          const result = (await axios.post('/buckets', bucketData))
        }

        await new Promise(r => setTimeout(r, 2000))

        context.commit('setLoadingState', {
          key: 'bucketSave',
          loadingState: {
            state: 'loaded'
          }
        })

        // Reload all the buckets to have clean data
        context.dispatch('loadBuckets')
      } catch(e) {
        console.error(`Failed to save bucket`)
        context.commit('setLoadingState', {
          key: 'buckets',
          loadingState: {
            status: 'error',
            errorMessage: e
          }
        })
      }
    },

    async loadBuckets(context) {
      try {
        context.commit('setLoadingState', {
          key: 'buckets',
          loadingState: {
            status: 'loading'
          }
        })
        
        const buckets = (await axios.get('/buckets')).data
        context.commit('setBuckets', buckets)

        context.commit('setLoadingState', {
          key: 'buckets',
          loadingState: {
            status: 'loaded'
          }
        })
      } catch(e) {
        console.error(`Failed to load buckets`)
        context.commit('setLoadingState', {
          key: 'buckets',
          loadingState: {
            status: 'error',
            errorMessage: e
          }
        })
      }
    },
    async loadSections(context) {
      try {
        const sections = await (await (await axios.get('/photos/sections')).data)
        context.commit('setSections', sections)
      } catch(e) {
        console.error(e, "Failed to load sections")
      }
    },
    async loadSectionDetails(context, sectionName) {
      try {
        const sectionDetails = await (await (await axios.get('/photos/sections/' + sectionName)).data)
        context.commit('mergeSectionDetails', sectionDetails)
        context.commit('mergeThumbnails', sectionDetails.content.thumbnails)
      } catch(e) {
        console.error(`Failed to load section details for ${sectionName}`)
      }
    },
    async loadFullPhoto(context, id) {
      try {
        context.commit('setPhoto', {
          id,
          data: {
            status: PhotoStatus.LOADING
          }
        })

        const photoData: ArrayBuffer = await (await axios.get(`/photos/${id}/file?format=base64`)).data
        const photoDetails = await (await axios.get(`/photos/${id}/details`)).data

        context.commit('setPhoto', {
          id,
          data: {
            status: PhotoStatus.LOADED,
            details: photoDetails,
            data: photoData
          }
        })

      } catch(e) {
        context.commit('setPhoto', {
          id,
          data: {
            status: PhotoStatus.ERROR
          }
        })
        console.log(e)
      }
    },
    async unloadFullPhoto(context, id) {
      context.commit('setPhoto', {
        id,
        data: {
          status: PhotoStatus.UNLOADED
        }
      })
    },
    async openLightTable(context, id) {
      context.dispatch('loadFullPhoto', id)
      context.commit('mergeStatus', {
        ...context.state.status,
        lightTable: {
          isOpen: true,
          id: id
        }
      })
    },
    async closeLightTable(context) {
      const loadedId = context.state.status.lightTable.id
      context.commit('mergeStatus', {
        ...context.state.status,
        lightTable: {
          isOpen: false,
          id: loadedId
        }
      })

      context.dispatch('unloadFullPhoto', loadedId)
    }
  },
  modules: {
  }
})
export default store