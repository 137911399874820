
















































import { Component, Vue } from 'vue-property-decorator';

@Component({
})
export default class Settings extends Vue {
  status = [{
      'name': 'file-scan',
      'status': 'processing',
  },
    {'name': 'thumbnails',
      'status': 'processing',
      'jobcount': '450'
    },
    {'name': 'skynet',
      'status': 'error'
    }]

/*
    buckets = [{
      'name': 'my-s3-photos',
      'status': 'ok',
      'error': '',
      'canDelete': true,
    },
    {
      'name': 'my-local-photos',
      'status': 'ok',
      'error': '',
      'canDelete': true,
    },
    {
      'name': 'my-broken-storage',
      'status': 'error',
      'error': 'Can\'t read file',
      'canDelete': true,
    }]
*/

    created() {
      this.$store.dispatch('loadBuckets')
    }

    get buckets() {
      return this.$store.state.buckets
    }
}
