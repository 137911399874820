import Vue from 'vue'
import VueRouter, { Route, RouteConfig } from 'vue-router'
import PhotoStream from '@/views/PhotoStream.vue'
import Settings from '@/views/Settings.vue'
import { getInstance } from '@/auth'


Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
  {
    path: '/',
    name: 'Home',
    component: PhotoStream,
    meta: {
      noAuth: true
    }
  },
  {
    path: "/settings",
    name: "Settings",
    component: Settings
  },
  {
    path: '/signup',
    name: 'Signup',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/SignUp.vue'),
    meta: {
      noAuth: true,
      noNav: true
    }
  },
  {
    path: '/storage/add',
    name: 'Storage > Add',
    component: () => import(/* webpackChunkName: "storage" */ '../views/ChangeStorage.vue'),
  },
  {
    path: '/storage/:id',
    name: 'Storage > Change',
    component: () => import(/* webpackChunkName: "storage" */ '../views/ChangeStorage.vue'),
    props: true
  }
]


export const authGuard = (to: Route, from: Route, next: Function) => {
  const authService = getInstance();

  if (to.matched.every(record => record.meta.noAuth)) {
    return next();
  }

  const fn = () => {
    // If the user is authenticated, continue with the route
    if (authService.isAuthenticated) {
      return next();
    }

    // Otherwise, log in
    authService.loginWithRedirect({ appState: { targetUrl: to.fullPath } });
  };

  // If loading has already finished, check our auth state using `fn()`
  if (!authService.loading) {
    return fn();
  }

  // Watch for the loading property to change before we check isAuthenticated
  authService.$watch("loading", loading => {
    if (loading === false) {
      return fn();
    }
  });
};

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach(authGuard)


export default router
