import { render, staticRenderFns } from "./PhotoSection.vue?vue&type=template&id=c1176462&scoped=true&"
import script from "./PhotoSection.vue?vue&type=script&lang=ts&"
export * from "./PhotoSection.vue?vue&type=script&lang=ts&"
import style0 from "./PhotoSection.vue?vue&type=style&index=0&id=c1176462&lang=sass&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c1176462",
  null
  
)

export default component.exports