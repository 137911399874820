









import { Component, Vue } from 'vue-property-decorator';
import PhotoSection from '@/components/PhotoSection.vue';
import StreamHeader from '@/components/StreamHeader.vue';

@Component({
  components: {
    PhotoSection,
    StreamHeader
  },
  props: {
    sections: Array
  },
  data: () => ({
    sectionHeights: {},
    sectionShouldLoad: {},
    animationFrameHandle: undefined
  })
})
export default class PhotoStream extends Vue {
  resizeObserver!: object
  sectionHeights!: Record<string, number>
  sections!: {sectionName: string; numPhotos: number}[]
  sectionShouldLoad!: Record<string, boolean>
  animationFrameHandle!: number

  mounted() {
    window.requestAnimationFrame(this.handleAnimation)
    this.handleAnimation()
  }

  created() {
    document.addEventListener('scroll', this.handleScroll)
  }

  destroyed() {
    document.removeEventListener('scroll', this.handleScroll)
  }

  get sectionPhotos() {
    return (sectionName: string) => this.$store.state.sectionPhotos[sectionName] || []
  }

  get sectionTopOffsets() {
    let tally = 0
    const offsets: Record<string, number> = {}
    for (const section of this.sections) {
      offsets[section.sectionName] = tally
      tally += this.sectionHeights[section.sectionName]
    }

    return offsets
  }

  updateSectionHeight({section, height}: any) {
    Vue.set(this.sectionHeights, section, height)
  }

  private handleAnimation() {
    // if section top offset within three viewports, then load.
    const topOfViewport = window.scrollY
    const windowHeight = window.innerHeight
    const topOfLoad = Math.max(topOfViewport - window.innerHeight * 2, 0)
    const bottomOfLoad = window.scrollY + windowHeight * 2 // x4 because we need to add window height to begin with


    for(const section of this.sections) {
      const offset = this.sectionTopOffsets[section.sectionName]
      const height = this.sectionHeights[section.sectionName]
      Vue.set(this.sectionShouldLoad, section.sectionName, (bottomOfLoad >= offset) && ((offset + height) >= topOfLoad))
    }
  }

  private handleScroll(ev: Event) {
    window.cancelAnimationFrame(this.animationFrameHandle)
    this.animationFrameHandle = window.requestAnimationFrame(this.handleAnimation)
  }
}
