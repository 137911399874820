













import { Vue, Component, Watch } from 'vue-property-decorator';
import PhotoStreamComponent from '@/components/PhotoStream.vue';

@Component({
  components: {
    PhotoStreamComponent,
  },
})
export default class PhotoStream extends Vue {
  created() {
    this.$store.dispatch('loadSections')
  }

  get sections() {
    return this.$store.state.sections
  }
}
