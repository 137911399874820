


























import Vue from 'vue'
import Component from 'vue-class-component';

import { sample } from "lodash";

import NavBar from '@/components/NavBar.vue';
import LightTable from '@/components/LightTable.vue';

const quips = [
  'Straightening the picture frames',
  'Sorting your garden gnomes',
  'Wiping the lenses',
  'Charging your camera batteries'
]

@Component({
  components: {
    NavBar,
    LightTable
  }
})
export default class App extends Vue {
  testing = false
  quip = ""

  login() {
    this.$auth.loginWithRedirect();
  }

  logout() {
    this.$auth.logout({
      returnTo: window.location.origin
    })
  }

  openLightTable(event: Event) {
    console.log(event)
  }

  created() {
    this.quip = sample(quips)!
  }
}
