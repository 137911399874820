














































import Vue from 'vue'
import Component from 'vue-class-component'

@Component({
  'name': "NavBar"
})
export default class NavBar extends Vue {
  searchShown = false
  menuItemsShown = false
  searchTerm = ""
  placeholder = "find any photos"

  showSearch() {
    this.searchShown = true

    this.$nextTick(() => (this.$refs.box as any as HTMLInputElement).focus())
  }

  hideSearch() {
    this.searchShown = false
  }

  toggleMenu() {
    if(!this.loggedIn())
      return
  
    this.menuItemsShown = !this.menuItemsShown
  }

  loggedIn() {
    return !this.$auth.isLoading && this.$auth.isAuthenticated
  }

  userName() {
    return this.$auth.user && this.$auth.user.given_name
  }

  login() {
    this.$auth.loginWithRedirect();
  }

  logout() {
    this.$auth.logout({
      returnTo: window.location.origin
    })
  }
}
