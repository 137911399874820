






import { Component, Vue } from 'vue-property-decorator';

@Component({
  props: {
    label: String
  }
})
export default class StreamHeader extends Vue {}
