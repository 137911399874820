


































import Vue from 'vue'
import Component from 'vue-class-component'
import { Watch } from 'vue-property-decorator'

@Component({})
export default class LightTable extends Vue {
  imageStatus() {
    return this.$store.state.photos[this.$store.state.status.lightTable.id]?.status
  }

  imageLoaded() {
    return this.imageStatus() == 'loaded'
  }

  imageData() {
    return this.$store.state.photos[this.$store.state.status.lightTable.id].data
  }

  date() {
    const { year, month, day, hour, minute, second, nanosecond } = this.details().timeTaken
    const date = new Date(year, month, day, hour, minute, second, nanosecond / 1e6)

    return date.toLocaleDateString()
  }


  get lightTableOpen() {
    return this.$store.state.status.lightTable.isOpen
  }

  @Watch('lightTableOpen')
  setBodyScroll(newValue: boolean) {
    if(newValue) {
      document.body.classList.add('overflow-hidden')
    } else {
      document.body.classList.remove('overflow-hidden')
    }
  }

  details() {
    return this.$store.state.photos[this.$store.state.status.lightTable.id].details
  }
}
